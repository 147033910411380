import React from 'react';
import './HomeBreadcrumb.scss';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

type Props = {
  lang: string;
};

/**
 * Home Breadcrumb
 *
 * @param props
 * @constructor
 */
const HomeBreadcrumb: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const homeURL = props.lang === 'en' ? '/' : '/fr/';

  return (
    <>
      <div className={'mdmp__breadcrumb-home'}>
        <Link to={homeURL}>{t('breadcrumbs.dataMarketplace')}</Link>
      </div>
    </>
  );
};

export default HomeBreadcrumb;
