import React, { ReactElement } from 'react';
import './Callout.scss';

type Props = {
  lang: string;
  title: string;
  linkTitle?: ReactElement;
  children: ReactElement | ReactElement[];
  additionalClass?: string;
};

/**
 * Callout
 *
 * @param props
 * @constructor
 */
const Callout: React.FunctionComponent<Props> = props => {
  return (
    <div
      className={
        'mdmp__callout ontario-callout' +
        (props.additionalClass ? props.additionalClass : '')
      }
    >
      <h2 className="ontario-callout__title ontario-h5">
        {props.title} {props.linkTitle ? props.linkTitle : ''}
      </h2>
      {props.children}
    </div>
  );
};

export default Callout;
