import { Link } from 'gatsby';
import React from 'react';
import './Breadcrumbs.scss';
import ChevronBreadcrumb from './ChevronBreadcrumb';
import HomeBreadcrumb from './HomeBreadcrumb';

type Props = {
  lang: string;
  bc?: {
    label: string;
    callBack?: (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      path: string,
    ) => void;
    path?: string;
  }[];
};

/**
 * Breadcrumbs
 *
 * @param props
 * @constructor
 */
const Breadcrumbs: React.FunctionComponent<Props> = props => {
  return (
    <>
      <div className="mdmp__breadcrumbs">
        {props.bc &&
          props.bc.map((v, k) => {
            if (v.label === 'home') {
              return <HomeBreadcrumb key={k} lang={props.lang} />;
            } else if (v.label === 'chevron') {
              return <ChevronBreadcrumb key={k} />;
            }

            return (
              <div key={k} className={'mdmp__breadcrumbs-item'}>
                {!v.path && <span>{v.label}</span>}
                {v.path && <Link to={v.path}>{v.label}</Link>}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Breadcrumbs;
