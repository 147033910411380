import React from 'react';
import './ChevronBreadcrumb.scss';

type Props = {
  key: number | string;
};

/**
 * Chevron Breadcrumb
 *
 * @constructor
 */
const ChevronBreadcrumb: React.FunctionComponent<Props> = () => {
  return (
    <svg
      className="mdmp__breadcrumb-chevron ontario-icon"
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
    >
      <use href="#ontario-icon-chevron-right"></use>
    </svg>
  );
};

export default ChevronBreadcrumb;
