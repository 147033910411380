import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Callout from '../components/common/callout/Callout';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import { getPageLink } from '../constants';
import './PricingPage.scss';
import { PageContentType } from '../context/PageContentType';

/**
 * Pricing Page
 *
 * @param props
 * @constructor
 */
const PricingPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.payment'),
      path: getPageLink(props.pageContext.lang, 'PRICING'),
    },
  ];

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'PRICING')}
      pageTitle={t('pricingPage.title')}
    >
      <div className="mdmp__pricing-page ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <h1>{t('pricingPage.title')}</h1>

          <p>{t('pricingPage.p01')}</p>
          <p>{t('pricingPage.topics.title')}</p>
          <ul>
            <li>{t('pricingPage.topics.item01')}</li>
            <li>{t('pricingPage.topics.item02')}</li>
            <li>{t('pricingPage.topics.item03')}</li>
            <li>{t('pricingPage.topics.item04')}</li>
            <li>{t('pricingPage.topics.item05')}</li>
          </ul>

          <hr />

          <h2>{t('pricingPage.subTitle01.title')}</h2>
          <p>{t('pricingPage.subTitle01.p01')}</p>
          <p>{t('pricingPage.subTitle01.p02')}</p>
          <p>{t('pricingPage.subTitle01.p03')}</p>
          <p>{t('pricingPage.subTitle01.p04')}</p>
          <p>{t('pricingPage.subTitle01.p05')}</p>
          <p>{t('pricingPage.subTitle01.p06')}</p>
          <p>{t('pricingPage.subTitle01.p07')}</p>
          <p>{t('pricingPage.subTitle01.p08')}</p>
          <p>{t('pricingPage.subTitle01.p09')}</p>

          <h2>{t('pricingPage.subTitle02.title')}</h2>
          <p>{t('pricingPage.subTitle02.p01')}</p>
          <p>{t('pricingPage.subTitle02.p02')}</p>

          <h2>{t('pricingPage.subTitle03.title')}</h2>
          <p>{t('pricingPage.subTitle03.p01')}</p>

          <h2>{t('pricingPage.subTitle04.title')}</h2>
          <p>{t('pricingPage.subTitle04.p01')}</p>
          <p>{t('pricingPage.subTitle04.p02')}</p>

          <ul>
            <li>{t('pricingPage.subTitle04.list.item01')}</li>
            <li>{t('pricingPage.subTitle04.list.item02')}</li>
            <li>{t('pricingPage.subTitle04.list.item03')}</li>
            <li>{t('pricingPage.subTitle04.list.item04')}</li>
            <li>{t('pricingPage.subTitle04.list.item05')}</li>
          </ul>

          <p>{t('pricingPage.subTitle04.p03')}</p>
          <p>{t('pricingPage.subTitle04.p04')}</p>
          <p>{t('pricingPage.subTitle04.p05')}</p>
          <p>{t('pricingPage.subTitle04.p06')}</p>

          <Callout
            lang={props.pageContext.lang}
            title={t('pricingPage.callout01.title')}
            additionalClass={'mdmp__yellow'}
          >
            <p>{t('pricingPage.callout01.p01')}</p>
            <p>{t('pricingPage.callout01.p02')}</p>
            <p>{t('pricingPage.callout01.p03')}</p>
            <p>{t('pricingPage.callout01.p04')}</p>
          </Callout>

          <h2>{t('pricingPage.subTitle05.title')}</h2>
          <p>{t('pricingPage.subTitle05.p01')}</p>
          <p>{t('pricingPage.subTitle05.p02')}</p>
          <p>{t('pricingPage.subTitle05.p03')}</p>
          <p>{t('pricingPage.subTitle05.p04')}</p>
          <p>{t('pricingPage.subTitle05.p05')}</p>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default PricingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
